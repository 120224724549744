import React from 'react';
import Logo from '../../assets/logo.png';

const Footer = (props) => {
  return (
    <div className="footer">
      <img src={Logo} alt="logo" />
      <p className='footer__desc'>
        As an Affiliate I may earn a commission from qualified Purchase of product and this website is not sponsored by
        amazon or other shopping platforms.{' '}
      </p>
      <p>
        Made with 💜 in India
      </p>
      <p>
        Designed by Rizwan.
      </p>
    </div>
  );
};

Footer.propTypes = {};

export default Footer;
