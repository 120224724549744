import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const Menu = ({ menuList }) => {
  const history = useHistory();
  const location = useLocation();
  const onMenuSelect = (menu) => {
    const searchParams = new URLSearchParams(location.search);
    const search = searchParams.get('search');
    let query = search ? `?search=${search}&` : '?';
    if (menu) {
      query += `menu=${menu}`;
    }

    history.replace(`/landing${query}`);
  };

  const [activeMenu, setActiveMenu] = React.useState('All');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const menu = searchParams.get('menu');
    if (menu) {
      setActiveMenu(menu);
    }else{
      setActiveMenu('All');
    }
  }, [location.search]);

  window.onscroll = function () {
    myFunction();
  };



  function myFunction() {
    const header = document.getElementById('myHeader');
    if(!header) return;
    const sticky = header.offsetTop;
    if (window.pageYOffset > sticky) {
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
    }
  }

  return (
    <div className="landingMenu">
      <div className="landingMenu__header">
        <h1>About us</h1>
        <p>
          Step into the world of innovation and style with StressFoxs Blog. Uncover the latest trends and explore a
          diverse array of unique products carefully curated to redefine your lifestyle. Join us on a journey of
          discovery where extraordinary finds await at every turn.
        </p>
      </div>
      <menu className="landingMenu__menu" id="myHeader">
        <button
          className={`landingMenu__menu-item ${activeMenu === 'All' ? 'landingMenu__menu-active' : ''}`}
          onClick={() => onMenuSelect('All')}
        >
          All
        </button>
        {menuList.map((menu, index) => {
          return (
            <button
              key={index}
              className={`landingMenu__menu-item ${activeMenu === menu ? 'landingMenu__menu-active' : ''}`}
              onClick={() => onMenuSelect(menu)}
            >
              {menu}
            </button>
          );
        })}
      </menu>
    </div>
  );
};

Menu.propTypes = {
  menuList: PropTypes.array
};

Menu.defaultProps = {
  menuList: PropTypes.array
};

export default Menu;
