import React, { useEffect } from 'react';
import Logo from '../../assets/logo.png';
import Search from '../../assets/search.svg';
import Mail from '../../assets/mail.svg';
import { useHistory, useLocation } from 'react-router-dom';
import '../../scss/header.scss';

const Header = (props) => {
  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get('search') || '';

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const menu = searchParams.get('menu');
    const search = searchParams.get('search');
    let query = menu ? `?menu=${menu}&` : '?';
    if (search) {
      query += `search=${search}`;
    }
    history.replace(`/landing${query}`);
  }, []);

  const onSearch = ({ target: { value } }) => {
    const searchParams = new URLSearchParams(location.search);
    const menu = searchParams.get('menu');
    let query = menu ? `?menu=${menu}&` : '?';
    if (value) {
      query += `search=${value}`;
    }
    history.replace(`/landing${query}`);
  };

  const mailTo = (e) => {
    window.location.href = 'mailto:hello@stressfox.com';
    e.preventDefault();
  };
  return (
    <div className="header" id="header">
      <div className="header__left">
        <img src={Logo} alt="Logo" className="header__logo" onClick={() => history.push('/landing')} />
        <p>Unique Products Showcase </p>
      </div>
      <div className="header__right">
        <div className="header__search">
          <input type="text" className="header__search-input" placeholder="Search" onChange={onSearch} value={search} />
          <img src={Search} alt="search" className="header__search-icon" />
        </div>
        <div className="header__icon" onClick={mailTo}>
          <img src={Mail} alt="mail" className="header__mail" />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {};

export default Header;
