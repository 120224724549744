import React from "react";
import Header from "../../components/Header";
import Menu from "./Menu";
import Products from "./Products";

import '../../scss/landing.scss';
import Footer from "./Footer";

import products from '../../data.json'

const Dashboard = (props) => {

  const scrollFunction = () => {
    const mybutton = document.getElementById('myBtn');
    if (mybutton) {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = 'block';
      } else {
        mybutton.style.display = 'none';
      }
    }
  };
  window.addEventListener('scroll', scrollFunction, true);

  const menuList = products.data.map((item, index) => {
    return item.category
  });

  return <>
    <Header />
    <div className="landing">
      <Menu menuList={menuList}/>
      <Products products={products.data}/>
    </div>
    <Footer/>
  </>
    ;
};

Dashboard.propTypes = {};

export default Dashboard;
