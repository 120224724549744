import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Amazon from '../../assets/amazon.svg';
import Amazonint from '../../assets/AmazonInt.svg';
import Ebay from '../../assets/Ebay.svg';
import Etsy from '../../assets/Etsy.svg';
import Flipkart from '../../assets/Flipkart.svg';
import Myntra from '../../assets/myntra.svg';
import Custom from '../../assets/Custom.svg';
import Alibaba from '../../assets/Alibaba.svg';
import Shopee from '../../assets/Shopee.svg';
import Lazada from '../../assets/Lazada.svg';
import Ikea from '../../assets/Ikea.svg';
import Youtube from '../../assets/Youtube.svg'

import Share from '../../assets/share.svg';
// import Ad from './Ad';
import InfiniteScroll from 'react-infinite-scroll-component';

const platformsLink = {
  Amazon: Amazon,
  Amazonint: Amazonint,
  Ebay: Ebay,
  Etsy: Etsy,
  Flipkart: Flipkart,
  Myntra: Myntra,
  Alibaba: Alibaba,
  Shopee: Shopee,
  Lazada: Lazada,
  ikea: Ikea,
  Custom: Custom,
  Youtube: Youtube
};
const Products = ({ products }) => {
  const location = useLocation();
  const [productList, setProductList] = useState([]);
  const [productListFiltered, setProductListFiltered] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const search = searchParams.get('search') ? searchParams.get('search').toLowerCase() : '';
    const menu = searchParams.get('menu');

    if (menu && menu !== 'All') {
      let filteredProducts = products.find((product) => product.category === menu)?.products || [];
      filteredProducts = filteredProducts.filter((product) => product.name.toLowerCase().includes(search));
      filteredProducts = filteredProducts.sort((a, b) => b.id- a.id);
      setProductList(filteredProducts);
      const list = filteredProducts.length === 0 ? [] : filteredProducts.splice(0, 10);
      setProductListFiltered(list);
    } else {
      let allProducts = products.reduce((acc, product) => {
        const filteredProducts = product.products.filter((item) => item.name.toLowerCase().includes(search));
        return [...acc, ...filteredProducts];
      }, []);
      allProducts = allProducts.sort((a, b) => b.id- a.id);
      setProductList(allProducts);
      const list = allProducts.length === 0 ? [] : allProducts.splice(0, 10);
      setProductListFiltered(list);
    }
  }, [location.search, products]);

  const fetchData = () => {
    setProductListFiltered(productListFiltered.concat(productList.splice(0, 10)));
  };

  return (
    <>
      <div className="landingProducts">
        <InfiniteScroll dataLength={productListFiltered.length} next={fetchData} hasMore>
          {productListFiltered
            .map((product, index) => {
              // if ((index + 1) % 6 === 0) {
              //   return (
              //     <React.Fragment key={product.name}>
              //       <Product product={product} />
              //       <Ad />
              //     </React.Fragment>
              //   );
              // }
              return <Product key={index} product={product} />;
            })}
        </InfiniteScroll>
      </div>
    </>
  );
};

Products.propTypes = {
  products: PropTypes.array
};

Products.defaultProps = {
  products: []
};

const Product = ({ product }) => {
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);

  const onShare = () => {
    const link = `${window.location.origin}${location.pathname}?search=${product.name}`;
    if (navigator.share) {
      navigator
        .share({
          title: product.name,
          url: link
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      navigator.clipboard.writeText(link);
    }
  };

  return (
    <div className="landingProducts__product">
      <div className="landingProducts__product-img">
        <img src={product.image} alt="product" />
      </div>
      <div className="landingProducts__product-info">
        <h3 className="landingProducts__product-name">{product.name}</h3>
        <p className="landingProducts__product-price"> $ {product.price}</p>
        <p className="landingProducts__product-description">{product.description}</p>
      </div>
      <div className="landingProducts__product-actions">
        {isExpanded ? (
          <div className="landingProducts__product-links">
            {product.links.map((link, index) => (
              <button
                className="landingProducts__product-linkButton"
                onClick={() => window.open(link.href)}
                key={index}
              >
                <img src={platformsLink[link.platform]} />
              </button>
            ))}
          </div>
        ) : (
          <button className="landingProducts__product-button" onClick={() => setIsExpanded(true)}>
            Buy here
          </button>
        )}

        <button className="landingProducts__product-share" onClick={onShare}>
          <img src={Share} alt="share" />
        </button>
      </div>
    </div>
  );
};

Product.propTypes = {
  product: PropTypes.object
};

export default Products;
